import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

var fadeUp = function(speed){
  return {
    opacity: 0,
    duration: speed,
    y: 50,
    stagger: 0.25
  };
};

var t1 = gsap.timeline({paused: true})
  // Start with page scrolled to top
  .to(window, {duration: 0, scrollTo: { y: 0 }})
	.from(".text-i_am, #heading-name, .typedContainer, .arrowcont", fadeUp(0.75))
  .from('#about', fadeUp(0.5))
  .from("header", {opacity: 0, duration: 1})
  .to(".arrowcont", 1 / 4, {y:-25, ease: "power2.out"})
  .to(".arrowcont", 1 / 2, {y:0, ease: "power2.out"});

gsap.from('.pagetitle', {
  opacity: 0,
  scrollTrigger: {
    trigger: '#heading-name',
    start: 'top top+=50',
    scrub: true
  }
});

window.onload = function() {
  t1.play();
  //t1.progress(1, false);
}

function scrollTo(target) {
  if (target) {
    let headerHeight = document.getElementsByTagName('header')[0].offsetHeight;

    gsap.to(window, {
      duration: 1,
      ease: "power4.out",
      scrollTo: {
        y: target,
        offsetY: headerHeight
      }
    }).then(function() {
      if (target.includes('contact')) {
        // Focus on first input in contact form
        document.getElementById("name").focus();
      }
    });
  }
}

// For newly opened link (e.g in new tab)
(function(){
  scrollTo(window.location.hash);
})();

// For opening link in the same page
const links = document.querySelectorAll('a');
links.forEach(function(link){
  if (link.hash.charAt() == '#') {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      scrollTo(this.hash);
    });
  }
});

// Icons - highlight on hover
document.querySelectorAll('.icons i').forEach(icon => {
  icon.addEventListener('mouseover', (event) => {
    icon.classList.add('colored');
  });

  icon.addEventListener('mouseleave', (event) => {
    icon.classList.remove('colored');
  });
});
