import './../sass/main.scss';
import Typed from 'typed.js';
import './animation';
import { gsap } from "gsap";

const bodyEl = document.querySelector("body");
const navToggler = document.getElementById("navigation-toggler");

function closeNav() {
    bodyEl.classList.remove("nav-open");
    navToggler.ariaExpanded = false;
    navToggler.innerHTML = 'Menu';
    document.ontouchmove = function (e) { return true; };
}

function openNav() {
    bodyEl.classList.add("nav-open");
    navToggler.ariaExpanded = true;
    navToggler.innerHTML = 'Close';
    document.ontouchmove = function (e) { e.preventDefault(); };

    gsap.to('.pagetitle', {
        opacity: 1,
        duration: 0.3,
    });
    gsap.from(".nav-main li", {
        opacity: 0,
        duration: 0.3,
        y: 20,
        //stagger: 0.10
    });
}

navToggler.addEventListener('click', function () {
    navToggler.ariaExpanded === 'true' ? closeNav() : openNav();
});

// Toggle other projects
const otherProjectsButton = document.getElementById('view-other-projects');
if (otherProjectsButton) {
    otherProjectsButton.addEventListener('click', function () {
        document.querySelector('.other-projects').classList.toggle('show');
    });
}

document.querySelectorAll("nav a, #darkmode-toggle").forEach(element => {
    element.addEventListener('click', closeNav);
});

const typedWords = {
    fr: [
        "sites web",
        "applications web",
        "plugins WordPress",
        "thèmes WordPress",
        "magasins eCommerce",
    ],
    en: [
        "websites",
        "web applications",
        "WordPress plugins",
        "WordPress themes",
        "eCommerce stores",
    ]
};

new Typed('.typed', {
    strings: bodyEl.classList.contains("fr") ? typedWords.fr : typedWords.en,
    typeSpeed: 60,
    backSpeed: 30,
    backDelay: 3000,
    loop: true,
    loopCount: false,
    showCursor: false
});

// Validate form
function validateForm() {
    let errors = 0;

    document.querySelectorAll('#name, #email, #message').forEach(input => {
        if (input.value.length < 1) errors++;
    });

    document.getElementById('submit').toggleAttribute('disabled', Boolean(errors));

    return !Boolean(errors);
}

document.querySelectorAll("#name, #email, #message").forEach(element => {
    element.addEventListener('keyup', validateForm);
});

document.getElementById('contactForm').addEventListener('submit', event => {
    event.preventDefault();
    submitForm();
});

let sent = false;
const submit_btn_text = document.getElementById('submit').value;
function submitForm() {
    if (!sent) {
        const submitBtn = document.getElementById('submit');

        submitBtn.value = bodyEl.classList.contains('fr') ? 'Envoi en cours...' : 'Sending...';

        fetch("/sendmessage.php", {
            method: 'post',
            body: JSON.stringify({
                "name":                 document.getElementById("name").value,
                "email":                document.getElementById("email").value,
                "message":              document.getElementById("message").value,
                "g-recaptcha-response": document.querySelector('#contactForm input[name="g-recaptcha-response"]')?.value || null
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            const resp = response.json();
            if (response.status !== 200) throw resp;
            return resp;
        })
        .then((res) => {
            // Success
            console.log('Message sent', res);
            // Set submit button text
            submitBtn.value = bodyEl.classList.contains('fr') ? 'Envoyé' : 'Sent';
            // Disable btn + add sent class
            submitBtn.classList.add('sent');
            submitBtn.toggleAttribute('disabled', true);
            sent = true;
        })
        .catch((error) => {
            console.log('Failed to send message', error);
            submitBtn.setAttribute('value', submit_btn_text);
            submitBtn.classList.remove('sent');
            submitBtn.toggleAttribute('disabled', false);
        });
    }
}

// Dark mode
var dark_mode_pref = typeof Storage !== 'undefined' ? localStorage.getItem('dark-mode') == 'true' : null;

if (dark_mode_pref) {
    document.body.classList.add('dark-mode');
}
/*else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    if (dark_mode_pref !== false) {
        document.body.classList.add('dark-mode');
    }
}*/

const darkmodeToggler = document.getElementById('darkmode-toggle');
if (darkmodeToggler) {
    darkmodeToggler.addEventListener('click', function() {
        document.body.classList.toggle('dark-mode');

        if (typeof Storage !== 'undefined') {
            localStorage.setItem('dark-mode', document.body.classList.contains('dark-mode'));
        }
    });
}
